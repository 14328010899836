import './App.css';
import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {


  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [query, setQuery] = useState('');

  const [currentDateTime, setCurrentDateTime] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email,
      name,
      phone:phoneNumber,
      message:query,
      resolveStatus:false
    };
    if (phoneNumber.length == 10) {
      try {
        const response = await fetch('https://api.admin.bigbooster.in/dishaonline/create-ticket', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const data1= await response.json()
          debugger
        if (data1.data.id.length>0) {
            toast.success('Help ticket has been submitted!', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          setEmail("")
          setName("")
          setPhoneNumber("")
          setQuery("")
        } else {
            toast.error('Failed to submit', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
          console.error('Form submission failed');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      toast.error("Please enter a valid phone number");
    }
  };


  return (
    <div className="App">

 <div className="min-h-screen flex items-center justify-center">
                {/*<div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">*/}
                {/*    <div className="flex justify-center mb-8">*/}
                {/*        <img src="https://bigbooster.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.ce9ad104.png&w=750&q=75" alt="Bigbooster"*/}
                {/*            className="w-20 rounded-full" />*/}
                {/*    </div>*/}
                {/*    <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8 mb-6">Bigbooster App Support</h1>*/}
                {/*    <form onSubmit={handleSubmit}>*/}
                {/*        <div className="mb-4">*/}
                {/*            <label htmlFor="nombre" className="block mb-2 text-sm text-gray-600">*/}
                {/*                Name</label>*/}
                {/*            <input type="text" id="nombre" name="nombre"*/}
                {/*                   className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"*/}
                {/*                   value={name}*/}
                {/*                   onChange={(e) => setName(e.target.value)}*/}
                {/*                   required/>*/}
                {/*        </div>*/}
                {/*        <div className="mb-4">*/}
                {/*            <label htmlFor="apellido" className="block mb-2 text-sm text-gray-600">Phone Number</label>*/}
                {/*            <input type="number" id="apellido" name="apellido"*/}
                {/*                   className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"*/}
                {/*                   value={phoneNumber}*/}
                {/*                   onChange={(e) => setPhoneNumber(e.target.value)}*/}
                {/*                   required/>*/}
                {/*        </div>*/}
                {/*        <div className="mb-4">*/}
                {/*            <label htmlFor="email" className="block mb-2 text-sm text-gray-600">*/}
                {/*                Email</label>*/}
                {/*            <input type="email" id="email" name="email"*/}
                {/*                   className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"*/}
                {/*                                           value={email}*/}
                {/*                                           onChange={(e) => setEmail(e.target.value)}*/}
                {/*                   required/>*/}
                {/*        </div>*/}
                {/*        <div className="mb-4">*/}
                {/*            <label  className="block mb-2 text-sm text-gray-600">Query</label>*/}
                {/*            <textarea*/}
                {/*                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"*/}
                {/*                                    value={query}*/}
                {/*                                    onChange={(e) => setQuery(e.target.value)}*/}
                {/*                                    required*/}
                {/*                                ></textarea>*/}
                {/*        </div>*/}
                {/*        <button type="submit"*/}
                {/*                className="w-full bg-gradient-to-r from-green-400 to-green-600 text-white py-2 rounded-lg mx-auto block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 mb-2">Submit*/}
                {/*        </button>*/}
                {/*    </form>*/}
                {/*</div>*/}

            <div className="grid m-2 grid-cols-3 max-sm:grid-cols-1 gap-4 bg-transparent">
                <div className="mt-auto mb-auto text-right max-sm:text-center">
                    <h1 className="text-5xl font-bold max-sm:text-2xl">Disha Online Test Support</h1>
                    <p className="py-6">Your Guide to Getting the Most Out of Disha Online Test</p>
                </div>
                <div className="col-span-2">
                    <form className="card bg-transparent" onSubmit={handleSubmit}>
                        <div className="card-body max-sm:p-0">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder="email"
                                    className="input rounded-[5px] input-bordered placeholder:text-xs focus:outline-none"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Name</span>
                                </label>
                                <input
                                    type="text"
                                    placeholder="name"
                                    className="input rounded-[5px] input-bordered placeholder:text-xs focus:outline-none"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Phone Number</span>
                                </label>
                                <input
                                    type="number"
                                    placeholder="phone number"
                                    className="input rounded-[5px] input-bordered placeholder:text-xs focus:outline-none"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Query</span>
                                </label>
                                <textarea
                                    className="textarea textarea-bordered placeholder:text-xs focus:outline-none rounded-[5px]"
                                    placeholder="query"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    required
                                ></textarea>
                            </div>

                            <div className="form-control mt-6">
                                <button className="btn btn-neutral rounded-[5px] capitalize" type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    </div>
        <ToastContainer />

    </div>
  );
}

export default App;
